body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
  font-weight: bold;
  font-family: 'InterVariable';
  font-size: 16px;
  color: #1a202c;
  line-height: 10px;
}

.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  font-size: 14px;
  font-weight: 400;
  font-family: 'InterVariable';
  color: #1a202c;
  size: 14px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
  font-size: 14px;
  font-family: 'InterVariable';
  font-weight: 600;
  color: #1a202c;
}

.NewFunnelchart:not(.svg-funnel-js--vertical) .svg-funnel-js__label:nth-child(2) {
  border-left: 0px;
  text-align: right;
  right: 30px;
}
.NewFunnelchart:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:nth-child(2)) {
  border-left: 1px solid #cbd5e0;
}
.NewFunnelchart:not(.svg-funnel-js--vertical) .svg-funnel-js__label:first-child {
  border-left: 0px;
}
.OldFunnelchart:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
  border-left: 1px solid #cbd5e0;
}
.ag-sort-indicator-icon.ag-sort-order {
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
